import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { FaEnvelope, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { IoMdTime } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { MdPolicy } from 'react-icons/md';

const Footer = ({ userLanguage }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <div className={`footer-container ${userLanguage?.includes("he") ? "rtl" : "ltr"}`}>
                <div className="footer-section">
                    <h4>{t('aboutUs')}</h4>
                    <p>{t('our_product')}</p>
                </div>

                <div className="footer-section">
                    <h4>{t('contactUs').toUpperCase()}</h4>
                    <div className="contact-details">
                        <div className="contact-item">
                            <IoMdTime size={25} className="footer-icon" />
                            <p style={userLanguage?.includes('he') ? { paddingRight: 2 } : null}>{t('we_are_open')}</p>
                        </div>
                        <div className="contact-item" style={{ marginLeft: 2 }}>
                            <FaEnvelope size={22} className="footer-icon" />
                            <a style={userLanguage?.includes('he') ? { paddingRight: 2 } : null} href="mailto:info@qrmoment.com">info@qrmoment.com</a>
                        </div>
                        {/* <div className="contact-item">
                            <FaRegAddressCard size={23} className="footer-icon" />
                            <p style={{ marginRight: 10 }}>{t('organization_address')}</p>
                        </div> */}
                        <div className="contact-item" >
                            <MdPolicy size={27} className="footer-icon" />
                            <p onClick={() => navigate('/PrivatePolicy', { replace: true })}
                                className="policy-link">{t('private_policy')}</p>
                        </div>

                    </div>
                </div>

                <div className="footer-section">
                    <h4>{t('followUs')}</h4>
                    <div className="social-icons">
                        <a href={userLanguage?.includes('he') ||
                            userLanguage?.includes('en') ?
                            "https://www.instagram.com/qrmoment.il/?igsh=MTB0aXVmZmJmYTAxeQ%3D%3D" :
                            "https://www.instagram.com/qrmoment.il.ru"} target="_blank" rel="noopener noreferrer">
                            <FaInstagram
                                size={30}
                                className="footer-social-icon" />
                        </a>
                        <a href={userLanguage?.includes('he') ||
                            userLanguage?.includes('en') ?
                            "https://www.facebook.com/people/QrMoment/61560580046442/?mibextid=LQQJ4d" :
                            "https://www.facebook.com/people/QrMoment/61561315898045/?mibextid=LQQJ4d&rdid=F7tR1Z0Nl5rTslPj&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FN293ceJWkFSzfN6s%2F%3Fmibextid%3DLQQJ4d"} target="_blank" rel="noopener noreferrer">
                            <FaFacebookF
                                size={30}
                                className="footer-social-icon" />
                        </a>
                    </div>
                </div>
            </div >
            <div className="footer-copyright">
                <p onClick={() => navigate('/PrivatePolicy', { replace: true })}>Copyright Ⓒ 2024 Ⓡ QrMoment </p>
            </div>
        </>
    );
}

export default Footer;
