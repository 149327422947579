import React from 'react';
import './OpenWhatsApp.css';
import { ImWhatsapp } from 'react-icons/im';

const OpenWhatsApp = ({ userLanguage }) => {
    const openWhatsApp = () => {
        const phoneNumber = '+972502324736'; // Замените на ваш номер телефона
        const message = 'Hello! I would like to chat with you.'; // Замените на ваше сообщение
        const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    return (
        <a href="https://wa.me/972502324736" target="_blank" rel="noopener noreferrer">
            <div className={userLanguage?.includes("he") ? "fixed-whatsapp-button-hebrew" : "fixed-whatsapp-button"} onClick={openWhatsApp}>
                <ImWhatsapp size={37} />
            </div>
        </a>
    );
};

export default OpenWhatsApp;
