import React, { useState, useRef, useEffect } from 'react';
import imageCompression from 'browser-image-compression';
import './PhotoUploader.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, uploadPhotos } from '../../Redux/actions/actions';
import Spinner from '../Spinner/Spinner-old';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CiSquarePlus } from "react-icons/ci";
import { RiDeleteBin6Line } from 'react-icons/ri';

const PhotoUploader = ({ userLanguage }) => {
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch]);

    const handlePhotoChange = async (e) => {
        setLoading(true);

        const files = e.target.files;

        if (!files || files?.length === 0) {
            return;
        }

        try {
            const newPhotos = await Promise.all(
                Array.from(files).map(async (file) => {
                    try {
                        const compressedFile = await compressImage(file);
                        return compressedFile;
                    } catch (error) {
                        console.error('Error handling photo:', error);
                        return null;
                    }
                })
            );

            const filteredPhotos = newPhotos.filter((photo) => photo !== null);
            setSelectedPhotos((prevPhotos) => [...prevPhotos, ...filteredPhotos]);
        } catch (error) {
            console.error('Error handling photos:', error);
            setLoading(true);
        }
        setLoading(false);
    };

    const compressImage = async (file) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true,
        };

        try {
            return await imageCompression(file, options);
        } catch (error) {
            throw new Error('Error compressing image:', error);
        }
    };

    const handleDeletePhoto = (index) => {
        setLoading(true);
        const updatedPhotos = [...selectedPhotos];
        updatedPhotos.splice(index, 1);
        setSelectedPhotos(updatedPhotos);
        setLoading(false);
    };

    const handlePhotoUploaded = async () => {
        setLoading(true)
        const result = await dispatch(uploadPhotos(userData.uid, selectedPhotos));
        if (result.success) {
            console.log('Фотографии успешно загружены через Redux.');
            setSelectedPhotos([]);
        } else {
            console.error('Ошибка при загрузке фотографий через Redux:', result.error);
            alert("Error uploading photos", result.error)
            navigate('/Error');
            setLoading(false)

        }
        setLoading(false)

    };

    return (
        <>
            {loading ?
                <Spinner loading={loading} />
                :
                <>
                    <div className='photo-uploader-container' dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                        <label>
                            <div className="custom-file-input">
                                <CiSquarePlus
                                    className='add-photo-btn'
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handlePhotoChange}
                                    style={{ display: 'none' }}
                                    ref={inputRef}
                                    multiple
                                />
                            </div>
                        </label>
                    </div>

                    {selectedPhotos?.length > 0 && (
                        <div className='new-photo-container'>
                            <div className='photo-grid'>
                                {selectedPhotos?.map((photo, index) => (
                                    <div key={index} className="photo-item">
                                        <img src={URL.createObjectURL(photo)} alt={`Selected ${index + 1}`} className="uploaded-photo" />
                                        <div className='btn-remove'>
                                            <RiDeleteBin6Line
                                                onClick={() => {
                                                    const confirmDelete = window.confirm(t('alert_are_you_sure_you_want_to_delete_this_photo'));
                                                    if (confirmDelete) {
                                                        handleDeletePhoto(index);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="upload-button-container">
                                <button
                                    className="upload-button"
                                    onClick={handlePhotoUploaded}
                                >
                                    {t('save')}
                                </button>
                            </div>
                        </div>
                    )}
                </>
            }
        </>
    );
};

export default PhotoUploader;
