import React from 'react';
import './PackagesOffer.css';
import { useTranslation } from 'react-i18next';
import { FaCheck } from "react-icons/fa";
import { Link } from 'react-router-dom';

const PackagesOffer = ({ userLanguage }) => {
    const { t } = useTranslation();
    const packages = [
        {
            type: 'standart',
            name: t('three_years'),
            features: [
                t('eternal_access_to_the_memory_site'),

                t('personal_site_about_your_loved_one_with_photographs_and_video'),

                t('online_support_when_filling_out_the_page'),

                t('code_made_of_durable_metal_with_an_elegant_design_standart'),

                t('delivery_of_a_ready_made_QR_code_across_Belarus_within_10_working_days'),
            ],
            price: 79,
            discountPrice: 199

        },
        {
            type: 'premium',
            name: t('five_years'),
            features: [
                t('eternal_access_to_the_memory_site_premium'),

                t('personal_site_about_your_loved_one_with_photographs_and_video'),

                t('online_support_when_filling_out_the_page'),

                t('code_made_of_durable_metal_with_an_elegant_design_premium'),

                t('delivery_of_a_ready_made_QR_code_across_Belarus_within_10_working_days'),

            ],
            price: 179,
            discountPrice: 299
        },
        {
            type: 'gold',
            name: t('seven_years'),
            features: [
                t('eternal_access_to_the_memory_site_gold'),

                t('personal_site_about_your_loved_one_with_photographs_and_video'),

                t('online_support_when_filling_out_the_page'),

                t('code_made_of_durable_metal_with_an_elegant_design_premium'),

                t('delivery_of_a_ready_made_QR_code_across_Belarus_within_10_working_days'),
            ],
            price: 79,
            discountPrice: 399

        },
    ];

    return (
        <div className={userLanguage?.includes('he') ? 'packages-container-hebrew' : 'packages-container'} dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>

            <h2>{t('services_price')}</h2>

            <div className="package-direction">
                {packages.map((pkg, index) => (
                    <div
                        key={index}
                        className={`package-option ${pkg.type === 'premium' ? 'premium' : ''} animation-delay-${index + 1}`}
                    >
                        <p>{pkg.name}</p>
                        <ul>
                            {pkg.features.map((feature, index) => (
                                <div key={index} style={{ display: "flex", minHeight: 50, minWidth: 200 }}>
                                    <FaCheck className="icon-size" />
                                    <li key={index}>
                                        {feature}
                                    </li>
                                </div>

                            ))}

                            {/* <div>
                                <h3 style={{ color: 'red', textDecoration: 'line-through', textAlign: 'center' }}>{pkg.price} ₪</h3>
                            </div> */}
                            <div className='package-price-container'>
                                <h1>{pkg.discountPrice} ₪</h1>
                            </div>

                            <div className=" package-option-btns">
                                <Link
                                    className="package-button"
                                    to={"/SignUpForm"}
                                    state={{
                                        userPackage: pkg.type,
                                        userPrice: pkg.discountPrice,
                                    }}>
                                    {t('BuyBtn')}
                                </Link>
                            </div>
                        </ul>
                    </div>
                ))}
            </div >
        </div >
    );
};

export default PackagesOffer;
