import React from 'react';
import './ErrorPage.css'
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ErrorPage = ({ userLanguage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className='error-page-container'>
        <div className='error-page-details'>
          <h2>{t('something_went_wrong')}</h2>
          <p>{t('we_apologize_for_the_inconvenience_Please_try_again_later')}</p>
          <button
            onClick={() => navigate('/')}
            className={userLanguage?.includes("he") ? "contact-button contact-button-hebrew" : "contact-button"}

          >
            {t('home_page')}
          </button>
        </div>
      </div>
      <Footer userLanguage={userLanguage} />
    </>
  );
};

export default ErrorPage;
