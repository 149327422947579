import React from 'react';
import './PrivacyPolicy.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PublicAgreementIsr = () => {
    const { t } = useTranslation();

    const navigate = useNavigate(); // Инициализация функции navigate для управления навигацией

    const handleGoBack = () => {
        navigate("/SignUpForm", { state: { publicAgreement: true } }); // Передача пропсов при переходе на предыдущую страницу

    };

    return (
        <div>

            <div className="privacy-policy-container">
                <h1 className="policy-heading">TERMS AND CONDITIONS</h1>
                <h2 className="policy-heading">May 2024</h2>
                <div className="policy-content">
                    <p>
                        These terms and conditions (“Terms and Conditions”) are a legally binding and enforceable agreement between, Osek Patur (Licensed Dealer), business registration number 334018512, located Bat Yam (“we”, ”our”, or “us”), and users who access, browse, use, purchase Services or otherwise interact with our website (“user/s”, “you” or “your”), available at qrmoment.co.il (“website”).
                    </p>

                    <strong>Acceptance of the Terms </strong>

                    <p>
                        THESE TERMS AND CONDITIONS, TOGETHER WITH OUR PRIVACY POLICY AND ANY ADDITIONAL TERMS THAT MIGHT BE INCLUDED IN OUR WEBSITE (COLLECTIVELY THE "TERMS"), SET FORTH AND SETTLE THE TERMS ACCORDING TO WHICH YOU MAY USE OUR WEBSITE. BY ACCESSING, BROWSING, OR OTHERWISE USING OUR WEBSITE YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THE TERMS. THEREFORE, IT IS RECOMMENDED TO CAREFULLY READ THE TERMS, BEFORE ACCESSING, USING, OR OTHERWISE INTERACTING WITH THIS WEBSITE.
                    </p>

                    <strong>Eligibility and Age Restriction</strong>

                    <p>
                        The use of this website and the Services offered therein is intended for individuals over the age of 18 only. You represent and warrant that you are at least 18 years old and legally eligible to enter into these Terms, or, where applicable, you have all proper authorization to enter into these Terms. If you are under the age of 18 or you are not legally eligible, you are required not to make any use of the website and Services available therein.
                    </p>

                    <strong>Amendments of the Terms</strong>

                    <p>
                        We reserve the right to periodically amend or revise these Terms at our sole discretion; such changes will be effective immediately upon the display of the revised Terms. The last revision date will be reflected below the title above. Your continued use of the website and Services available therein following such amendments constitutes your acknowledgment and consent of such amendments to the Terms and your agreement to be bound by them, and thus, it is recommended to review these Terms periodically. In the event of material changes, we will make our best efforts to post notifications.
                    </p>

                    <strong>Our Website, Service & Content offered</strong>

                    <p>
                        Our website provides general information and resources regarding our business, services, and products, and any other content related to it, and may include, inter alia, articles, blogs, audio, images, reports, graphics, logos, graphics, etc. (collectively the "Content").
                        In addition, the website provides you with communications means which you can use to contact us, for example, if you have any inquiry regarding our business, Services, or products, or sign up for our newsletter and mailing list.
                        In addition, the description of our Services or products on the website is for general information and marketing purposes only and there may be discrepancies between such description and the actual service. In any event, only an official document on our behalf or a designated agreement executed with us for its Services will bind us.
                        We make reasonable efforts to ensure that the Content is up-to-date and accurate; however, it does not guarantee that no errors, mistakes, or inaccuracies will occur, and will not be held responsible for this matter.
                        The use or reliance on the Content is at your sole responsibility and risk, and we hereby disclaim any responsibility or liability for any decision made, or action taken or not taken, based on the Content, which is offered to users as-is.

                    </p>

                    <strong>Paid Services</strong>

                    <p>
                        We develop personalized memorial webpages and help preserve the memories of deceased loved ones for generations to come. This memorial webpage will contain photos, videos, letters, and everything else that will help future generations learn about their roots. To visit the memorial webpage we make long-lasting QR codes forged from durable metals resistant to temperature fluctuations and weather changes. (together the “Services").
                        The Services purchase is permitted for any individual who is at least 18 years and/or entities represented by authorized personnel with the authority to bind them to a contractual agreement; and holds a valid credit card issued by a recognized bank or other financial institutions which we support; and  authorize us to charge your card for total amount of the purchase, including applicable taxes.
                        When performing any online operation, you will be required to enter complete and correct information required and requested by us or the system, including first name, last name, full address, I.D number, E-mail address, and payment details.
                        Approval of the transaction by the credit card company issuing the card was used to make the online booking is a prerequisite for approval of any online operation. In the event that the transaction is not approved by the financial organization, the transaction will not be valid and the order will be void and canceled.
                        We will not be responsible for any error made by you while entering information for the purpose of an online purchase, including a customer's personal identification information. Notwithstanding the aforesaid, we reserve the right to cancel any such booking.
                        In rare cases possible mistakes in information about the Services on the website, including their prices. These mistakes result from human error or typos. In these, you will not be able to benefit from the error and you will be asked to purchase the service at the correct price. We may cancel or change any promotion at any time.
                        The prices do not include any additional or other expenses that may apply.

                    </p>

                    <strong>Cancellation Policy</strong>

                    <p>
                        The Services may be canceled according to the applicable law and no later than 14 days from the date of the transaction, provided cancellation is submitted within seven working days prior to the scheduled Services (if applicable).
                        The above 14-day period is extended to four months if you have a disability, you are a senior citizen, or a new repatriate (as such terms are defined in the applicable law), provided you include information regarding this in a dialogue between us.
                        If you have the right to cancel the purchase in accordance with the Consumer Protection Law or as agreed otherwise with us, you will be able to do so by submitting a cancellation notice to our e-mail: info@qrmoment.com. In the cancellation notice, you must specify your name, ID number, and information about the product or service you want to cancel.
                        In the event of cancellation as mentioned above, cancellation will entail a cancellation fee of 5% of the value of the transaction or 100 NIS, whichever is lower.
                    </p>

                    <strong>Use Restrictions</strong>

                    <p>
                        You hereby represent and warrant that you will not: use the website and Content in unlawful, illegal, fraudulent or inappropriate manner; circumvent, disable or otherwise interfere with security-related features of the website; copy, reproduce, republish, upload, post (unless where specifically permitted by us, transmit, or otherwise distribute, the website, Content, or any part thereof, nor remove, deface, obscure, or alter the website or any Content therein including any copyright notices, trademarks, or other proprietary rights; use the website and Content for any non-personal or commercial purposes; use the website and Content for benchmarking purposes; assert any proprietary rights in or to the Content or website; use our website to collect any information, including  personal information, whether in electronic means or other means, through hacking or mining, including for the purposes of unauthorized mailing or using electronic means of penetration or any other means, including scripts; use our name, logo or trademarks without our prior written consent; and use the website and Content in breach of third parties’ rights or our rights, including intellectual property rights and privacy rights, or in breach of these Terms.
                        Without derogating from any other right or remedy we shall be entitled to under these Terms or applicable law, in any event, any suspicion by us that the user's use of the website does not comply with the provisions of these Terms or applicable law, we may track the user's use of the website, prevent the user from accessing the website, or disclose such information to third parties who will prove, at our sole determination, that they were harmed by the user's infringing activity as well as take any other action that we deem appropriate to protect its property, rights and third parties rights.
                    </p>

                    <strong>Intellectual Property</strong>

                    <p>
                        The website and Content (excluding Third-Party Content) are owned or contributed to us, including, but not limited to, any design, trade names, trademarks, logos, images, software etc. Except as explicitly provided herein, no license, right, title, or interest to the Content shall be licensed to you, and we reserve any and all rights, title, and ownership of the website and Content. You shall not use our copyrights, trademarks, trade names, or other Intellectual Property in any way except to the limited extent as may be expressly agreed in these Terms. You may not remove or delete any intellectual property-related notice or indications posted on the website.
                    </p>

                    <strong>Third-Party Content </strong>

                    <p>
                        The Website, Services, and/or Content may further include information or links to third parties’ websites and resources not operated or owned by us ("Third-Party Content"). By reviewing, using, or otherwise accessing such Third-Party Content, you will be subject to their terms of service and policies. We have no control over third parties’ websites, not the content provided therein, and we do not, nor we are obligated to, monitor them and we hereby disclaim all liability or responsibility related to such Third-Party Content.  Inclusion of Third-Party Content in our website does not indicate our support, endorsement, or approval of such content or any other relationship with these websites or their operators. We do not guarantee the functionality of such links. We may, at its sole discretion, remove any link from the website at any time.
                    </p>

                    <strong>Privacy Practices</strong>

                    <p>
                        We respect your privacy rights. Our Privacy Policy provides information regarding our data collection and processing practices related to this website's user and is incorporated herein by reference.
                    </p>

                    <strong>Website Availability and Changes</strong>

                    <p>
                        We reserve the right to revise, update, or make any changes to the website, Services, and Content as well as to cease the operation of the website or any part thereof, including the Services offered therein, temporarily or permanently, at any time, according to its sole discretion and without prior notice. We do not guarantee that the website will operate or be available at any time, nor that no interruptions or errors will occur.
                    </p>

                    <strong>Disclaimer and Limitation of Liability</strong>

                    <p>
                        EXCEPT AS EXPLICITLY PROVIDED HEREIN, THE WEBSITE, SERVICES, AND CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND. WE DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, AND MAKE NO REPRESENTATION OR WARRANTIES OF ANY KIND, RELATED TO THE WEBSITE AND SERVICES AND CONTENT, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE SUCH AS THE SUCCESS OF OUR SERVICERS. WE MAKE NO REPRESENTATION OR WARRANTIES THAT THE WEBSITE AND SERVICES AND CONTENT ARE OR WILL BE AVAILABLE FOR USE IN ANY PARTICULAR LOCATION OR AT A SPECIFIC TIME, THAT THE WEBSITE WILL BE SECURED, UNINTERRUPTED OR ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, NOR THAT CONTENT WILL BE ACCURATE OR RELIABLE. ALSO, WE MAKE NO REPRESENTATION OR WARRANTIES THAT THE SERVICES RENDERED HEREIN WILL ACHIVE INDIVIDUALS’ OR GROUPS’ GOALS. YOU AGREE THAT WE WILL NOT BE HELD RESPONSIBLE FOR ANY DECISION MADE OR ACTION TAKEN OR NOT TAKEN IN RELIANCE ON THE WEBSITE OR SERVICES OR CONTENT NOR DO WE ASSUME ANY RESPONSIBILITY FOR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE OR INCIDENTAL DAMAGES, OR DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, LOSS OF BUSINESS INFORMATION, OR OTHER PECUNIARY LOSS, ARISING OUT OF THE USE OF THE WEBSITE AND SERVICES AND CONTENT, EVEN IF WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES.
                    </p>

                    <strong>Indemnification</strong>

                    <p>
                        You agree to defend, indemnify, and hold us harmless and our respective officers, directors, employees, and agents from any third-party claims, damages, liabilities, and expenses (including reasonable attorney’s fees) arising from your use of the website, Services or Content that does not comply with these Terms or made in breach of any applicable law.
                    </p>

                    <strong>Jurisdiction and Dispute Resolution</strong>

                    <p>
                        These Terms shall be governed by and construed in accordance with the laws of the State of Israel. You hereby agree to resolve any dispute you have exclusively with the competent court in Tel-Aviv, Israel.
                    </p>

                    <strong>Miscellaneous</strong>

                    <p>
                        <strong>Entire Agreement</strong> - these Terms constitute the entire understanding between the parties relating to the subject matter herein.
                    </p>
                    <p> <strong>Assignment</strong>- these Terms and any right granted herein shall not be assigned by you without our prior written consent. We may assign our rights and obligations set forth herein at any time, at its sole discretion.
                    </p>
                    <p>  <strong>Severability</strong> - should one or more of the provisions of these Terms be determined to be invalid, unlawful, or unenforceable in any respect, the validity, legality, and enforceability of the remaining provisions of these Terms shall not in any way be affected or impaired by such determination and will remain in full force and effect, and the provision affected will be construed to be enforceable to the maximum extent permissible by law.
                        Waiver - without derogating from the above, any delay or omission by either party to exercise any right under these Terms shall not be construed to be a waiver of such right. A waiver by either party of any of the performance provisions of these Terms shall not be construed to be a waiver of any succeeding performance or breach.
                    </p>

                    <strong>Contact Us</strong>
                    <p>If you have any questions about this website or these Terms, you may contact us, as follows:
                    </p>
                    <p>Through the digital contact forms available on the website;
                    </p>
                    <p>By email: info@qrmoment.com;
                    </p>
                    <p>By phone +972 522355320.
                    </p>
                </div>
                <div className="back-button-container">
                    <button
                        className="package-button"
                        onClick={handleGoBack}>
                        {t('i_agree_with_the_public_offer')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PublicAgreementIsr;