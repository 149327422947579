import React, { useEffect, useRef, useState } from 'react';
import { app } from '../../Components/Firebase/Firebase';
import { getDownloadURL, getStorage, listAll, ref } from 'firebase/storage';
import './PersonHistory.css';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import VideoGallery from '../VideoGallery/VideoGallery';
import { useDispatch, useSelector } from 'react-redux';
import noImage from '../../Images/no_data/no-image.png';
import FreeTextSection from '../FreeTextSection/FreeTextSection';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';

import locationImage from '../../Images/locationImage.png';

import mingcute from '../../Images/mingcute.png';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { IoLocationOutline } from 'react-icons/io5';
import fallbackImage from '../../Images/home-page-images/photo_book_open.jpg'; // Импортируем локальное изображение

const PersonHistory = ({ storedUserId, userLanguage, setLoading, EnterWithQrCode }) => {

    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.userData);
    const [showFreeTextForm, setShowFreeTextForm] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
    const storage = getStorage(app);
    const [slidesToShow, setSlidesToShow] = useState(3); // Значение по умолчанию для веб-версии
    const [backgroundPhotoUrl, setBackgroundPhotoUrl] = useState('');

    const [photoUrls, setPhotoUrls] = useState([]);

    const navigate = useNavigate();

    const { t } = useTranslation();

    const {
        // email,
        birthDatePersone,
        deathDatePersone,
        firstNamePersone,
        lastNamePersone,
        // fathersNamePersons,
        storyPersone,

        placeOfBirth,
        memorialPlace,
        spouse,
        children,
        education,
        hobbies,
        freeTexts,
        userLocation,
        uid

    } = currentUser;


    useEffect(() => {
        const handleResize = () => {
            // if (freeTexts?.length === 1) {
            //     setSlidesToShow(1);
            // }
            // else if (freeTexts?.length === 2) {
            //     setSlidesToShow(2);
            // }
            if (window.innerWidth <= 600) {
                setSlidesToShow(1); // Для мобильной версии
            }
            else if (window.innerWidth <= 1000) {
                setSlidesToShow(2); //для планшета
            }
            else if (window.innerWidth > 1001) {
                setSlidesToShow(3); // Для веб-версии
            }
        };

        handleResize(); // Вызываем функцию сразу, чтобы установить начальное значение

        window.addEventListener('resize', handleResize); // Слушаем изменения размера экрана

        return () => window.removeEventListener('resize', handleResize); // Убираем слушатель при размонтировании компонента
    }, [freeTexts?.length]);

    useEffect(() => {
        if (EnterWithQrCode
            & firstNamePersone === null
            || deathDatePersone === null
            || firstNamePersone === null
            || lastNamePersone === null
            || storyPersone === null
        ) {
            navigate('/UpdateEmail')
        }
    }, [EnterWithQrCode, deathDatePersone, firstNamePersone, lastNamePersone, navigate, storyPersone])

    useEffect(() => {
        if (!EnterWithQrCode) {
            if (
                birthDatePersone === null ||
                deathDatePersone === null ||
                firstNamePersone === null ||
                lastNamePersone === null ||
                storyPersone === null
            ) {
                setLoading(false)

                navigate(`/${storedUserId}/AboutPersonComponent`, { state: { storedUserId: storedUserId }, replace: true });

            }
        }
    }, [EnterWithQrCode, birthDatePersone, deathDatePersone, firstNamePersone, lastNamePersone, navigate, setLoading, storedUserId, storyPersone]);


    //проверяем если данные undefined то мы включаем загрузку
    useEffect(() => {
        if (!EnterWithQrCode) {
            if (
                birthDatePersone === undefined ||
                deathDatePersone === undefined ||
                firstNamePersone === undefined ||
                lastNamePersone === undefined ||
                storyPersone === undefined
            ) {
                setLoading(true);
            }
            else {
                setLoading(false)
            }
            setLoading(false)

        }
    }, [EnterWithQrCode, birthDatePersone, deathDatePersone, firstNamePersone, lastNamePersone, setLoading, storyPersone])

    // Проверяем storedUserId при каждом обновлении компонента
    useEffect(() => {
        if (storedUserId === null) {
            navigate(`/${userLanguage}`);
        }
    }, [storedUserId, userLanguage, navigate]);



    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                // Получение URL-адресов фото профиля
                const profileStorageRef = ref(storage, `${uid}/profile`);
                const profileResult = await listAll(profileStorageRef);
                const profileUrls = await Promise.all(profileResult.items.map((item) => getDownloadURL(item)));
                setPhotoUrls(profileUrls);

                // Получение URL-адреса фонового фото
                const backgroundStorageRef = ref(storage, `${uid}/background_photo/profile_background.jpg`);
                const backgroundDownloadURL = await getDownloadURL(backgroundStorageRef);
                setBackgroundPhotoUrl(backgroundDownloadURL);
            } catch (error) {
                setBackgroundPhotoUrl(fallbackImage); // Установить резервное изображение при ошибке
            } finally {
                setLoading(false);
            }
        };

        if (uid) {
            fetchPhotos();
        }
    }, [dispatch, uid, setLoading, storage]);

    // useEffect(() => {
    //     const fetchPhotos = async () => {
    //         // setLoading(true);
    //         // dispatch(getUserData());

    //         try {
    //             // Получение URL-адресов фото профиля
    //             const profileStorageRef = ref(storage, `${uid}/profile`);
    //             const profileResult = await listAll(profileStorageRef);
    //             const profileUrls = await Promise.all(profileResult.items.map((item) => getDownloadURL(item)));
    //             setPhotoUrls(profileUrls);

    //             // Получение URL-адреса фонового фото
    //             const backgroundStorageRef = ref(storage, `${uid}/background_photo/profile_background.jpg`);
    //             const backgroundDownloadURL = await getDownloadURL(backgroundStorageRef);
    //             setBackgroundPhotoUrl(backgroundDownloadURL);
    //         } catch (error) {
    //             console.error('Error loading images:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     if (uid) {
    //         fetchPhotos();
    //     }
    // }, [dispatch, uid, setLoading, storage]);

    function reverseDateFormat(originalDate) {
        if (!originalDate) {
            // Возвращаем пустую строку или другое значение, в зависимости от вашего случая
            return '';
        }

        // Разбиваем строку с датой на массив [год, месяц, день]
        const parts = originalDate?.split('-');

        // Создаем новый объект даты, задавая его аргументами день, месяц и год
        const reversedDate = new Date(parts[0], parts[1] - 1, parts[2]);

        // Используем методы объекта даты для получения дня, месяца и года
        const day = reversedDate.getDate();
        const month = reversedDate.getMonth() + 1; // Месяцы в JavaScript считаются с 0, поэтому добавляем 1
        const year = reversedDate.getFullYear();

        // Форматируем компоненты даты, чтобы были двухзначными, если нужно
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Возвращаем перевернутую дату в формате "дд.мм.гггг"
        return `${formattedDay}.${formattedMonth}.${year}`;
    }

    const createDetailItem = (label, value) => {
        if (value !== "" | value !== null) {
            return (
                <div className='container-detail'>
                    <span className="detail-label"> {label}:</span>
                    <span style={{ marginLeft: 5 }} />
                    <span className="detail-value"> {value}</span>
                </div>
            );
        }
        return null;
    }

    const handlePhotoClick = (index) => {
        setSelectedPhotoIndex(index);

    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Проверяем, что фотография открыта и клик был вне ее области
            if (selectedPhotoIndex !== null && !event.target.closest('.selected-photo-container')) {
                setSelectedPhotoIndex(null);
            }
        };

        // Добавляем обработчик события для клика вне элемента фотографии
        document.addEventListener('mousedown', handleOutsideClick);

        // Удаляем обработчик при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [selectedPhotoIndex]);


    const openGoogleMaps = () => {
        if (!userLocation) {
            console.error('User location is not available.');
            return;
        }

        const { latitude, longitude } = userLocation;
        const destination = `${latitude},${longitude}`;

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude: destLat, longitude: destLng } = position.coords;
                    const origin = `${destLat},${destLng}`;
                    window.open(`https://www.google.com/maps/dir/${origin}/${destination}`);
                },
                error => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    // const stories = [
    //     {
    //         title: "Приключение",
    //         content: "Однажды, отправившись в кругосветное путешествие, Александр случайно обнаружил древний храм в отдаленном уголке света. Внутри он нашел мистический артефакт, который, как говорили легенды, придавал своему обладателю способность видеть радугу даже в самый пасмурный день."
    //     },
    //     {
    //         title: "Влияние на окружающих",
    //         content: "Этот артефакт стал символом для Александра, и он решил поделиться своей радугой с миром. Он начал организовывать благотворительные акции, собирать средства для поддержки тех, кто нуждается, и проводить мастер-классы по фотографии для детей."
    //     },
    //     {
    //         title: "Наследие",
    //         content: "Хотя Александр больше нет с нами, его наследие продолжает жить в сердцах тех, кто его знал. Люди помнят его как сияющую личность, способную приносить свет и цвет в самые темные моменты жизни. Его друзья продолжают воплощать его идеалы, делая мир лучше вместе."
    //     }
    // ];

    // Проверяем количество слов в тексте
    const [modalIsOpenSelectedText, setModalIsOpenSelectedText] = useState(false);

    const [selectedText, setSelectedText] = useState("");

    const handleThreeDotsClick = (text) => {
        setSelectedText(text);
        setModalIsOpenSelectedText(true);
        setModalIsOpen(true)
    };

    // const [isExpanded, setIsExpanded] = useState(false);
    // const [isVisible, setIsVisible] = useState(false);
    const storyRef = useRef(null);

    // const splitTextIntoChunks = (text, chunkSize) => {
    //     const lines = text?.split('\n');
    //     const chunks = [];
    //     for (let i = 0; i < lines?.length; i += chunkSize) {
    //         chunks?.push(lines?.slice(i, i + chunkSize).join('\n'));
    //     }
    //     return chunks;
    // };

    // const storyChunks = splitTextIntoChunks(storyPersone, 2);

    // const toggleExpansion = () => {
    //     setIsExpanded(!isExpanded);
    // };

    useEffect(() => {
        const options = {
            threshold: 0.1,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        if (storyRef.current) {
            observer.observe(storyRef.current);
        }

        return () => {
            if (storyRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                observer.unobserve(storyRef.current);
            }
        };
    }, []);

    return (
        <>
            <div className='pesone-history-container' dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>

                {/* <div class="pesone-history-stories">
                    {stories?.map((story, index) => {
                        return (

                            <div key={index} className='pesone-history-story'>

                                <h2 style={{ display: 'flex', marginTop: story.title.length <= 11 ? 35 : '' }} >
                                    <div className="stick" style={{ marginTop: story.title.length > 11 ? 30 : '' }} />
                                    {story.title.toUpperCase()}
                                    <div className="stick" style={{ marginTop: story.title.length > 11 ? 30 : '' }} />
                                </h2>
                                <p>
                                    {story.content}
                                </p>
                            </div>
                        )
                    })}
                </div> */}

                {/* modal window for slider */}

                <div
                    className='persone-add-background-foto'
                    style={{ backgroundImage: `url(${backgroundPhotoUrl || fallbackImage})` }}
                >
                    <div className='pesone-history-details'>
                        {photoUrls?.length > 0 ? (
                            <div className="container">
                                {photoUrls?.map((url, index) => (
                                    <div key={index}>
                                        <img
                                            className="photo"
                                            src={url}
                                            alt={`Img-${index}`}
                                            loading="eager"
                                            onClick={() => handlePhotoClick(index)}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="container" style={{ position: 'relative' }}>
                                <img
                                    className="no-photo"
                                    src={noImage}
                                    alt="img"
                                    loading="eager"
                                />
                                {window.location.pathname.includes('PersoneHistory') && (
                                    <button
                                        onClick={() => navigate(`/${storedUserId}/SettingsPersoneHistory`)}
                                        className={userLanguage?.includes('he') ? "button-overlay-hebrew package-button " : "package-button button-overlay"}
                                    >
                                        {t('add_photo')}
                                    </button>
                                )}
                            </div>
                        )}

                        <div style={{ display: 'inline-block' }} dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
                            <div className="about-persone">

                                {(lastNamePersone !== undefined && firstNamePersone !== undefined) ?
                                    `${lastNamePersone?.toUpperCase()} ${firstNamePersone?.toUpperCase()}` :
                                    t('first_and_Last_Name')
                                }
                                {/* {fathersNamePersons !== undefined
                                    ? ` ${fathersNamePersons?.toUpperCase()}` : null} */}

                                <p style={{ fontSize: 20 }}>
                                    {(birthDatePersone !== undefined && deathDatePersone !== undefined) ?
                                        `${reverseDateFormat(birthDatePersone)} - ${reverseDateFormat(deathDatePersone)}` :
                                        t('date_of_Birth')}
                                </p>
                                <div className="detail-item">
                                    {education === undefined ?
                                        <p>{createDetailItem(t('education'), "-")}</p>

                                        : (
                                            <p>{createDetailItem(t('education'), education)}</p>
                                        )}
                                    {spouse === undefined ?
                                        <p>{createDetailItem(t('spouse'), "-")}</p>
                                        : (
                                            <p>{createDetailItem(t('spouse'), spouse)}</p>
                                        )}
                                    {children === undefined ?
                                        <p>{createDetailItem(t('children'), "-")}</p>
                                        : (
                                            <p>{createDetailItem(t('children'), children)}</p>
                                        )}
                                    {hobbies === undefined ?
                                        <p>{createDetailItem(t('hobby'), "-")}</p>
                                        : (
                                            <p>{createDetailItem(t('hobby'), hobbies)}</p>
                                        )}
                                    {placeOfBirth === undefined ? (
                                        <p>{createDetailItem(t('place_of_Birth'), "-")}</p>
                                    ) : (
                                        <p>{createDetailItem(t('place_of_Birth'), placeOfBirth)}</p>
                                    )}
                                    {memorialPlace === undefined ?
                                        <p>{createDetailItem(t('memorial_site'), "-")}</p>
                                        : (
                                            <p>{createDetailItem(t('memorial_site'), memorialPlace)}</p>
                                        )}
                                </div>

                            </div>
                        </div>

                    </div>


                    <div className='story-container' ref={storyRef}>
                        <div>

                            <p className='story-text-comp'> {storyPersone}</p>
                            <p className='story-text-phone'> {storyPersone}</p>


                        </div>

                        {/* {isExpanded && (
                            <IoIosArrowUp
                                size={50}
                                onClick={toggleExpansion}
                                className='story-toggle-button'
                            />
                        )} */}
                    </div>

                    <div>
                        {freeTexts?.length > 0 ?
                            <>
                                <div className='pesone-history-memories'>
                                    {freeTexts?.length > 0 && (
                                        <div>
                                            <h2>{t('memories')?.toUpperCase()}</h2>
                                            <div className='slide-content'>
                                                {freeTexts?.length === 1 ? (
                                                    <div className='pesone-history-memories-carousel'>
                                                        <div className='pesone-history-memories-border'>
                                                            <img src={mingcute} alt="mingcute" loading="eager" />
                                                            <p className="memory-text" style={{ textAlign: 'center' }}>
                                                                {freeTexts[0].freeTextData}
                                                            </p>
                                                            <h3 style={{ textAlign: 'center' }}>{freeTexts[0].authorOfText}</h3>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <Slider slidesToShow={slidesToShow}>
                                                        {freeTexts?.map((text, index) => (
                                                            <div key={index} className='pesone-history-memories-carousel'>
                                                                <div className='pesone-history-memories-border'>
                                                                    <img src={mingcute} alt="mingcute" loading="eager" />
                                                                    <p className="memory-text" style={{ textAlign: 'center' }}>
                                                                        {/* Проверяем количество слов и отображаем три точки при необходимости */}
                                                                        {text.freeTextData?.split(" ").length > 25 ? (
                                                                            <>
                                                                                {text.freeTextData?.split(" ").slice(0, 25).join(" ")}
                                                                                <span style={{ textDecoration: "underline", cursor: "pointer" }}
                                                                                    onClick={() => handleThreeDotsClick(text.freeTextData)}
                                                                                >...</span>
                                                                            </>
                                                                        ) : (
                                                                            text.freeTextData
                                                                        )}
                                                                    </p>
                                                                    <h3 style={{ textAlign: 'center' }}>{text.authorOfText}</h3>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div style={{ marginTop: 60, textAlign: 'center' }}>
                                    {window.location.pathname.includes('PersoneHistory') && (
                                        <div className='pesone-history-btns'>
                                            {!showFreeTextForm && (
                                                <button
                                                    onClick={() => {
                                                        setShowFreeTextForm(true);
                                                        setModalIsOpen(true);
                                                    }}
                                                    className="package-button"
                                                >
                                                    {t('add_your_memory')}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                            :
                            <>
                                {window.location.pathname.includes('PersoneHistory') && (
                                    <div className={userLanguage?.includes("he") ? 'pesone-history-memories_hebrew' : 'pesone-history-memories'}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2>{t('memories')}</h2>
                                            <div className='pesone-history-btns'>
                                                {!showFreeTextForm && (
                                                    <button
                                                        onClick={() => {
                                                            setShowFreeTextForm(true);
                                                            setModalIsOpen(true);
                                                        }}
                                                        className="package-button"
                                                    >
                                                        {t('add_your_memory')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                    </div>

                    {userLocation ?
                        <div className='pesone-history-memories' >
                            <h2>{t('location')}</h2>
                            <p className='pesone-history-location-p'>{t('provide_the_exact_location_of_the_burial_site_so_that_loved_ones_can_easily_find_the_memorial_of_their_beloved')}</p>

                            <div className='pesone-history-location' style={{ position: 'relative' }} >
                                <img src={locationImage} alt="img" loading="eager" className='persone-history-img-map' />
                                <div className='pesone-history-location-btn'>

                                    <button
                                        className="package-button local-btn"
                                        onClick={openGoogleMaps}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <IoLocationOutline size={28} color='#ff0000' />

                                            {t('location')}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {window.location.pathname.includes('PersoneHistory') && (

                                <div className='pesone-history-memories'>
                                    <h2>{t('location')}</h2>
                                    <p className='pesone-history-location-p'>{t('provide_the_exact_location_of_the_burial_site_so_that_loved_ones_can_easily_find_the_memorial_of_their_beloved')}</p>

                                    <div className='pesone-history-location' style={{ position: 'relative' }} >
                                        <img src={locationImage} alt="img" loading="eager" className='persone-history-img-map' />
                                        <div className='pesone-history-location-btn'>

                                            <button
                                                className="package-button local-btn"
                                                // className='regular-button local-btn'
                                                onClick={() => navigate(`/${storedUserId}/SettingsPersoneHistory`, { state: { numberTab: 4 } })}
                                            >
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    <IoLocationOutline size={28} color='#ff0000' />
                                                    {t('location')}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    }


                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        <PhotoGallery setLoading={setLoading} storedUserId={storedUserId} />
                    </div>

                    <div style={{ marginTop: 20, marginBottom: 40 }}>
                        <VideoGallery setLoading={setLoading} storedUserId={storedUserId} />
                    </div>
                    <Footer userLanguage={userLanguage} />

                </div >

                {modalIsOpenSelectedText && (
                    <Modal
                        isOpen={modalIsOpenSelectedText}
                        onRequestClose={() => setModalIsOpenSelectedText(false)}
                        className="custom-modal"
                    >
                        <div className="modal-slider">
                            <p>{selectedText}</p>
                            <button
                                className='regular-button'
                                style={{ fontSize: 15 }}
                                onClick={() => setModalIsOpenSelectedText(false)}>
                                {t('close')}
                            </button>

                        </div>
                    </Modal>
                )
                }

                {/* modal window for photos */}
                {
                    storedUserId && (
                        <div>
                            {showFreeTextForm && (
                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={() => {
                                        setShowFreeTextForm(false);
                                        setModalIsOpen(false);
                                        setSelectedPhotoIndex(null); // Сбрасываем индекс при закрытии модального окна
                                    }}
                                    contentLabel="Free Text Modal"
                                    className="custom-modal"
                                >
                                    <h2>{t('add_your_memory')}</h2>

                                    <FreeTextSection
                                        storedUserId={storedUserId}
                                        setShowFreeTextForm={setShowFreeTextForm}
                                        userLanguage={userLanguage}
                                        moreBiography={false}
                                    />
                                </Modal>
                            )}

                        </div>
                    )
                }
            </div >
        </>
    );
};


export default PersonHistory;