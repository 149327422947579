import React, { useState, useRef, useEffect, useCallback } from 'react';
import './SignUpForm.css';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CountrySelector from '../../Components/CountrySelector/CountrySelector';
import PhoneInputField from '../../Components/PhoneInputField/PhoneInputField';
import sha256 from 'crypto-js/sha256';
import { useDispatch } from 'react-redux';
import { registerWithEmailAndPassword } from '../../Components/Firebase/Firebase';

const SignUpForm = ({ userLanguage, setLoading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { publicAgreement, userPrice, userPackage } = location?.state || {};

  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [registrationError, setRegistrationError] = useState('');

  const [city, setCity] = useState('');
  const [country, setCountry] = useState('IL');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [flatNumber, setFlatNumber] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [description, setDescription] = useState('');
  const [phone, setPhone] = useState('+972');
  const [saveUserPrice, setSaveUserPrice] = useState(userPrice);
  const [saveUserPackage, setSaveUserPackage] = useState(userPackage);

  const [status, setStatus] = useState(new URLSearchParams(location.search).get('status'));

  const passwordInputRef = useRef(null);
  const [isCheckedAgreement, setIsCheckedAgreement] = useState(publicAgreement || false);

  const handleCheckboxChange = () => {
    setIsCheckedAgreement(!isCheckedAgreement);
  };

  const handleCloseAlertModal = () => {
    setRegistrationError('');
    setLoading(false);
  };


  const heshbonNumber = Math.floor(Math.random() * 100000);
  const [orderNumber, setOrderNumber] = useState(heshbonNumber);


  const [paymentUrl, setPaymentUrl] = useState('');
  const [apiLogin] = useState('pp1004275');
  const [apiKey] = useState('6CC9618BD879414A2582B76F607F69B2');
  const [apiUrl] = useState('https://allpay.to/app/?show=getpayment&mode=api5');

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedData = JSON.parse(storedUserData);
      setFullName(parsedData.fullName || '');
      setCity(parsedData.city || '');
      setStreet(parsedData.street || '');
      setHouseNumber(parsedData.houseNumber || '');
      setFlatNumber(parsedData.flatNumber || '');
      setZipCode(parsedData.zipCode || '');
      setDescription(parsedData.description || '');
      setPhone(parsedData.phone || '+972');
      setEmail(parsedData.email || '');
      setSaveUserPrice(parsedData.userPrice || '');
      setSaveUserPackage(parsedData.userPackage || '');
      setPassword(parsedData.password || '');
      setSaveUserPrice(parsedData.userPrice || '');

    }
  }, []);

  useEffect(() => {
    if (location.state?.userPrice !== undefined) {
      setSaveUserPrice(location.state.userPrice);
    }
    if (location.state?.fullName !== undefined) {
      setFullName(location.state.fullName);
    }
  }, [location.state?.userPrice, location.state?.fullName]);

  const firstNamePersone = null;
  const lastNamePersone = null;
  const fathersNamePersons = null;
  const birthDatePersone = null;
  const deathDatePersone = null;
  const storyPersone = null;
  const placeOfBirth = null;
  const memorialPlace = null;
  const spouse = null;
  const children = null;
  const education = null;
  const hobbies = null;
  const userLocation = null;

  const isUserRegistered = true;
  const createUserWithQrCode = false;

  const [dataLoaded, setDataLoaded] = useState(false);

  const loadUserData = useCallback(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const parsedData = JSON.parse(storedUserData);
      setFullName(parsedData.fullName || '');
      setCity(parsedData.city || '');
      setStreet(parsedData.street || '');
      setHouseNumber(parsedData.houseNumber || '');
      setFlatNumber(parsedData.flatNumber || '');
      setZipCode(parsedData.zipCode || '');
      setDescription(parsedData.description || '');
      setPhone(parsedData.phone || '+972');
      setEmail(parsedData.email || '');
      setSaveUserPrice(parsedData.userPrice || '');
      setSaveUserPackage(parsedData.userPackage || '');
      setPassword(parsedData.password || '');
      setOrderNumber(parsedData.orderNumber || Math.floor(Math.random() * 100000));
      setDataLoaded(true);
    }
  }, []);


  const addDataToFirebase = useCallback(async () => {
    setLoading(true);
    try {
      await registerWithEmailAndPassword(
        email,
        password,
        fullName,
        saveUserPrice,

        city,
        country,
        description,
        flatNumber,
        houseNumber,
        orderNumber,

        phone,
        street,
        zipCode,
        saveUserPackage,
        navigate,
        dispatch,
        t,

        firstNamePersone,
        lastNamePersone,
        fathersNamePersons,
        birthDatePersone,
        deathDatePersone,
        storyPersone,
        placeOfBirth,
        memorialPlace,
        spouse,
        children,
        education,
        hobbies,
        userLocation,

        isUserRegistered,
        createUserWithQrCode,
      );
      setLoading(false);
      localStorage.removeItem('userData');
    } catch (error) {
      console.error("Ошибка при регистрации:", error);
      setRegistrationError(t('registration_error_message'));
      setLoading(false);
    }
  }, [setLoading, email, password, fullName, saveUserPrice, city, country, description, flatNumber, houseNumber, orderNumber, phone, street, zipCode, saveUserPackage, navigate, dispatch, t, isUserRegistered, createUserWithQrCode]);


  const statusHandled = useRef(false);


  useEffect(() => {

    loadUserData();
    const intervalId = setInterval(() => {
      if (!dataLoaded) {
        loadUserData();
      } else {
        clearInterval(intervalId);
      }
    }, 1000); // Попробуйте повторную загрузку данных каждую секунду

    return () => clearInterval(intervalId);
  }, [dataLoaded, loadUserData]);

  console.log(registrationError,'status');

  useEffect(() => {
    if (dataLoaded) {

      // const status = new URLSearchParams(location.search).get('status');
      if (status && !statusHandled.current) {
        statusHandled.current = true;
        if (status === 'ok') {
          addDataToFirebase();
        } else if (status === 'no') {
          setRegistrationError('Payment failed. Please try again later');
          setStatus(null)
        }
      }
    }
  }, [location.search, dataLoaded, addDataToFirebase, status]);



  const getApiSignature = (params) => {
    const sortedParams = Object.keys(params)
      .sort()
      .filter(key => params[key] !== '' && key !== 'sign')
      .map(key => params[key]);
    const signatureString = sortedParams.join(':') + ':' + apiKey;
    return sha256(signatureString).toString();
  };

  const getPaymentKey = async () => {
    const requestData = {
      name: fullName,
      login: apiLogin,
      order_id: heshbonNumber.toString(),
      amount: parseFloat(userPrice),
      currency: 'ILS',
      lang: userLanguage.toUpperCase(),
      notifications_url: 'https://qrmoment.com/',
      
      //test
      // success_url: "http://localhost:3000/SignUpForm?status=ok",
      // backlink_url: "http://localhost:3000/SignUpForm?status=no",

      //prod .co.il
      success_url: "https://qrmoment.co.il/SignUpForm?status=ok",
      backlink_url: "https://qrmoment.co.il/SignUpForm?status=no",

       //prod .com
      // success_url: "https://qrmoment.com/SignUpForm?status=ok",
      // backlink_url: "https://qrmoment.com/SignUpForm?status=no",

      client_name: fullName,
      client_email: 'info@qrmoment.com',
      client_phone: '+972522355320',
    };

    requestData.sign = getApiSignature(requestData);

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      if (!response.ok) {
        throw new Error('Failed to process payment.');
      }

      const responseData = await response.json();
      setPaymentUrl(responseData.payment_url);
    } catch (error) {
      console.error('Error:', error);
      alert('Payment failed. Please try again later.');
    }
  };

  const addShippingToLocalStorage = () => {
    const shippingData = {
      fullName,
      city,
      country,
      street,
      houseNumber,
      flatNumber,
      zipCode,
      description,
      phone,
      userPrice,
      email,
      password,
      userPackage: userPackage || saveUserPackage,
      orderNumber: heshbonNumber.toString(),
    };
    localStorage.setItem('userData', JSON.stringify(shippingData));
  };

  useEffect(() => {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  }, [paymentUrl]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;


    if (!isCheckedAgreement) {
      setRegistrationError(t('alert_please_enter_agree_to_terms'));
      setLoading(false);
    } else if (!fullName) {
      setRegistrationError(t('alert_please_enter_your_name'));
      setLoading(false);
    } else if (!emailRegex.test(email)) {
      setRegistrationError(t('alert_please_enter_a_valid_email_address'));
      setLoading(false);
    } else if (!passwordRegex.test(password)) {
      setRegistrationError(t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number'));
      setLoading(false);
    } else if (password !== confirmPassword) {
      setRegistrationError(t('alert_passwords_do_not_match'));
      setLoading(false);
    } else {
      console.log("Adding data to local storage and getting payment key");
      addShippingToLocalStorage();
      getPaymentKey();
    }

    setLoading(false);
  };

  return (
    <div className="signup-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
      <div className="signup-title">
        <h1>{t('signup')}</h1>
      </div>
      <form className="signup-content signup-card-left" onSubmit={handleSubmit}>
        <div className="signup-card">
          <h2>{t('delivery_address')}</h2>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
            placeholder={t('first_and_Last_Name')}
            pattern="[^<>]*"
            />
          <CountrySelector
            userLanguage={userLanguage}
            country={country}
            setCountry={setCountry}
            label={t('country')}
          />
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
            placeholder={t('city')}
            pattern="[^<>]*"
            />
          <input
            type="text"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            required
            placeholder={t('street')}
            pattern="[^<>]*"
            />
          <input
            type="number"
            value={houseNumber}
            onChange={(e) => setHouseNumber(e.target.value)}
            required
            placeholder={t('house_number')}
            pattern="[^<>]*"
            />
          <input
            type="number"
            value={flatNumber}
            onChange={(e) => setFlatNumber(e.target.value)}
            required
            placeholder={t('apartment_number')}
            pattern="[^<>]*"
            />
          <input
            type="number"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            placeholder={t('index')}
            pattern="[^<>]*"
            />
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('comment_for_delivery')}
            pattern="[^<>]*"
            />
          <PhoneInputField
            value={phone}
            onChange={setPhone}
            userLanguage={userLanguage}
          />
        </div>

        <div className="signup-card signup-card-right">
          <h2>{t('billing_information')}</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            placeholder={t('email')}
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder={t('password')}
            ref={passwordInputRef}
            pattern="[^<>]*"
            />
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder={t('confirm_password')}
            pattern="[^<>]*"
            />

          <div className="agreement">
            <input
              className="agreement-checkbox"
              type="checkbox"
              checked={isCheckedAgreement}
              onChange={handleCheckboxChange}
              pattern="[^<>]*"
              />
            <Link to="PublicAgreement">{t('i_agree_with_the_public_offer')}</Link>
          </div>

          <button
            className="package-button"
            type="submit">
            {t('pay')}
          </button>

          {registrationError && (
            <div className="alert-modal show">
              <div className="alert-modal-content">
                <span className="close" onClick={handleCloseAlertModal}>&times;</span>
                <p>{registrationError}</p>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
