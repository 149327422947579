import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import './HomeScreen.css';
import { MdOutlineLockClock } from "react-icons/md";
import { ImQrcode } from "react-icons/im";
import { BsPersonHearts } from "react-icons/bs";
import { IoVideocamOutline } from "react-icons/io5";

import { IoImagesOutline } from "react-icons/io5";
import { GoPerson } from "react-icons/go";
import { TbMessageCircleHeart } from "react-icons/tb";
import { MdPersonPinCircle } from "react-icons/md";
import { LuPenSquare } from "react-icons/lu";
import { MdOutlineContactSupport } from "react-icons/md";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import image1 from '../../Images/home-page-images/photo(3).jpg';
import image2 from '../../Images/home-page-images/photo.jpg';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactForm.js';
import FAQSection from '../FAQItem/FAQItem.js';


const HomeScreen = ({ userLanguage, isMobile, contactRef }) => {
  const { t } = useTranslation();

  const images = [
    {
      src: image1,
      alt: "Image 1",
      title: t('qr_moment_a_modern_service_to_save_the_moments_of_life'),
      description: t('a_commemorative_page_that_commemorates_precious_moments_that_stay_with_us_for_life'),
      button: {
        text: t('create_a_memorial_page'),
        link: '/PackagesOffer'
      },
    },
    {
      src: image2,
      alt: "Image 2",
      title: t('how_it_works'),
      // description_long: 'תגי זיכרון, שנוצרו מחומרים עמידים, הופכים למפתח נצחי למורשת אינסופית. על כל תגי זיכרון יש דף אישי השומר את הסיפור של היקרים לכם.',
      icons: [
        {
          icon: <MdOutlineLockClock />,
          alt: 'Icon 1',
          label: t('save_the_moments'),
          desc: t("upload_photos_videos_and_tell_stories")
        },
        {
          icon: <ImQrcode />,
          alt: 'Icon 2',
          label: t('place_the_qr_code'),
          desc: t('place_the_memory_qr_code_in_the_selected_place')
        },
        {
          icon: <BsPersonHearts />
          ,
          alt: 'Icon 3',
          label: t('dive_into_memories'),
          desc: t('scan_the_code_and_remember_precious_moments')
        },
      ],
      button: {
        text: t('create_a_memorial_page'),
        link: '/PackagesOffer'
      },

    },
  ];

  useEffect(() => {
    const justifyTextWithoutExtraSpace = () => {
      const elements = document.querySelectorAll('.justify-without-space');

      elements.forEach(element => {
        const words = element.textContent.split(' ');
        const spaceWidth = (element.offsetWidth - element.scrollWidth) / (words.length - 1);
        let newText = '';

        for (let i = 0; i < words.length - 1; i++) {
          newText += words[i] + ' '.repeat(spaceWidth);
        }

        newText += words[words.length - 1];
        element.innerHTML = newText;
      });
    };

    justifyTextWithoutExtraSpace();

    window.addEventListener('resize', justifyTextWithoutExtraSpace);

    return () => {
      window.removeEventListener('resize', justifyTextWithoutExtraSpace);
    };
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          position: 'absolute',
          right: window.innerWidth < 600 ? '15px' : '50px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          position: 'absolute',
          left: window.innerWidth < 600 ? '15px' : '50px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2
        }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [visibleIndex, setVisibleIndex] = useState(-1);
  const sectionRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleIndex(0); // Запускаем анимацию при видимости раздела
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (visibleIndex >= 0) {
      const interval = setInterval(() => {
        setVisibleIndex((prevIndex) => (prevIndex + 1));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [visibleIndex]);

  const videoRef = useRef(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVideoVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  // const contactRef = useRef(null);
  const [isContactVisible, setIsContactVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsContactVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      if (contactRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(contactRef.current);
      }
    };
  }, [contactRef]);

  const faqRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              entry.target.classList.add('animate');
            }, 500); // Задержка 2 секунды
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (faqRef.current) {
      observer.observe(faqRef.current);
    }

    return () => {
      if (faqRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(faqRef.current);
      }
    };
  }, []);

  const footerRef = useRef(null);
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsFooterVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.2 });

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <div >
      <div className="home-remember-images" style={{ position: 'relative' }}>
        <Slider {...settings}>
          {images?.map((image, index) => {
            return (
              <>
                <div key={index} style={{ position: 'relative', backgroundColor: '#dcdce2' }}>
                  <img src={image.src} alt={image.alt} loading="eager" />
                  <div className="home-image-text">
                    <h4 style={{ textAlign: 'center' }}>{image.title}</h4>
                    <p className='home-image-description'>{image.description}</p>
                    <p>{image.description_long}</p>

                    <div className="home-image-icon-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"} >
                      {image.icons?.map((icon, iconIndex) => (
                        <div key={iconIndex} className="home-image-icon-item">
                          <div className="icon">
                            {icon.icon}
                          </div>
                          <div>
                            <p>{icon.label}</p>
                            <p className='home-image-icon-desc'>{icon.desc}</p>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="home-image-button-container">
                      {image.button && (
                        <a href={image.button.link} className="home-image-button">
                          {image.button.text}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
      </div>


      <div
        className={
          userLanguage?.includes('he') ? 'home-text-container-hebrew' : 'home-text-container'
        }
        ref={sectionRef}
        dir={userLanguage?.includes("he") ? "rtl" : "ltr"}
      >
        <h4>{t('what_can_you_put_on_a_memory_page')}</h4>

        <div className="home-text-container-options">

          <div className={`icon-container-options ${visibleIndex >= 0 ? 'visible' : ''}`}>
            <div className="icon-background">
              <IoImagesOutline className="icon-options" />
            </div>
            <p className="icon-label-options">{t('photo')}</p>
            <p className="icon-description-options">
              {t('your_loved_ones_photo_gallery_which_excites_and_revives_the_precious_moments_is_available_to_you_at_any_time')}
            </p>
          </div>

          <div className={`icon-container-options ${visibleIndex >= 1 ? 'visible' : ''}`}>
            <div className="icon-background">
              <IoVideocamOutline className="icon-options" />
            </div>
            <p className="icon-label-options">{t('video')}</p>
            <p className="icon-description-options">

              {t('the_videos_of_your_loved_one_telling_the_story_of_his_life_and_the_most_significant_moments')}            </p>
          </div>
          {isMobile ? null :
            <div className={`icon-container-options ${visibleIndex >= 2 ? 'visible' : ''}`}>
              <div className="icon-background">
                <MdPersonPinCircle className="icon-options" />
              </div>
              <p className="icon-label-options">{t('geolocation')}</p>
              <p className="icon-description-options">{t('the_location_of_the_deceaseds_grave_so_that_the_place_can_be_easily_found')}</p>
            </div>
          }
        </div>

        <div className="home-text-container-options">
          <div className={`icon-container-options ${visibleIndex >= 3 ? 'visible' : ''}`}>
            <div className="icon-background">
              <LuPenSquare className="icon-options" />
            </div>
            <p className="icon-label-options">{t('online_editor')}</p>
            <p className="icon-description-options">{t('changes_to_the_page_at_any_time_updating_and_adding_new_memories_and_data')}</p>
          </div>

          <div className={`icon-container-options ${visibleIndex >= 4 ? 'visible' : ''}`}>
            <div className="icon-background">
              <MdOutlineContactSupport className="icon-options" />
            </div>
            <p className="icon-label-options">{t('support')}</p>
            <p className="icon-description-options">{t('technical_support_at_every_stage_of_creating_and_preserving_memories')}</p>
          </div>
          <div className={`icon-container-options ${visibleIndex >= 5 ? 'visible' : ''}`}>
            <div className="icon-background">
              <GoPerson className="icon-options" />
            </div>
            <p className="icon-label-options">{t('life_story')}</p>
            <p className="icon-description-options">{t('the_life_story_of_your_loved_one_showing_the_most_important_moments')} </p>
          </div>
          <div className={`icon-container-options ${visibleIndex >= 6 ? 'visible' : ''}`}>
            <div className="icon-background">
              <TbMessageCircleHeart className="icon-options" />
            </div>
            <p className="icon-label-options">{t('memories')}</p>
            <p className="icon-description-options">
              {t('memories_of_your_loved_ones_life_full_of_exciting_events_and_significant_moments')}
            </p>
          </div>
          {isMobile ?
            <div className={`icon-container-options ${visibleIndex >= 2 ? 'visible' : ''}`}>
              <div className="icon-background">
                <MdPersonPinCircle className="icon-options" />
              </div>
              <p className="icon-label-options">{t('geolocation')}</p>
              <p className="icon-description-options">{t('the_location_of_the_deceaseds_grave_so_that_the_place_can_be_easily_found')}</p>
            </div>
            : null}
        </div>
      </div>

      <div
        className='home-video-container-main'
        dir={userLanguage?.includes('he') ? 'rtl' : 'ltr'}
      >
        <div
          className={`home-video-container ${isVideoVisible ? 'visible' : ''}`}
          ref={videoRef}
        >
          <h4>{t('how_it_works')}</h4>
          <div className="video-text-container">
            <iframe
              width="800"
              height="805"
              src="https://www.youtube.com/embed/HFb-A4Fhziw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <Link className="package-button" to={"/PackagesOffer"}>
            {t('create_a_memorial_page')}
          </Link>
        </div>
      </div>

      <div
        className={`contact-form-wrapper ${isContactVisible ? 'animate' : ''}`}
        ref={contactRef}
        id="contact-form">
        <div className='home-video-container-main'>
          <h4>{t('contactUs')}</h4>
          <ContactForm userLanguage={userLanguage} isMobile={isMobile} />
        </div>
      </div>


      <div
        className='home-question-container'
        dir={userLanguage?.includes('he') ? 'rtl' : 'ltr'}
        ref={faqRef}
      >
        <div className='home-question-content'>
          <h3>{t('questions_and_answers_that_are_important_to_know')}</h3>
          <FAQSection userLanguage={userLanguage} />
        </div>
      </div>
      <div ref={footerRef} className={`footer-wrapper ${isFooterVisible ? 'visible' : ''}`}>
        <Footer userLanguage={userLanguage} />
      </div>


    </div>
  );
};

export default HomeScreen;
