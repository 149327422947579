import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { app, db, updateEmail, updatePassword } from "../../../Components/Firebase/Firebase";

import './UpdateEmail.css';
import { useTranslation } from "react-i18next";
import { updateUserData } from "../../../Redux/actions/actions";
import { doc, setDoc } from "firebase/firestore";
import PhoneInputField from "../../../Components/PhoneInputField/PhoneInputField";

const UpdateEmail = ({ userLanguage, setLoading }) => {

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('+972');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [registrationError, setRegistrationError] = useState('');

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const alertPleaseEnterYourName = t('alert_please_enter_your_name');
    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number = t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number');

    const handleUpdateEmailAndPassword = async () => {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;
        setLoading(true);
        if (!fullName) {
            setRegistrationError(alertPleaseEnterYourName);
            setLoading(false);
            return;
        }

        else if (!emailRegex.test(email || newEmail)) {
            setRegistrationError(alert_please_enter_a_valid_email_address);
            setLoading(false);
            return;
        }
        else if (!passwordRegex.test(password || newPassword || confirmNewPassword)) {
            setRegistrationError(alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number);
            setLoading(false);
            return;
        }
        else if (!phone) {
            setRegistrationError(t('alert_please_enter_your_phone'));
            setLoading(false);
            return;
        }

        else {
            const auth = getAuth(app);

            try {
                // Авторизуем пользователя с текущими учетными данными
                await signInWithEmailAndPassword(auth, email, password);

                // Если авторизация прошла успешно, обновляем электронный адрес и пароль
                const user = auth.currentUser;

                // Добавляем данные в коллекцию new-users для отправки email покупателю
                const otherCollectionDocRef = doc(db, "new-users", user.uid);
                await setDoc(otherCollectionDocRef, {
                    uid: user.uid,
                    price: "500",
                    country: "Israel",
                    email: newEmail,
                    fullName,
                    phone,
                    dateOfCreation: new Date().toISOString(),
                });
                await updateEmail(user, newEmail);
                await updatePassword(user, newPassword);

                //обновляем данные в коллекции
                const updatedData = {
                    phone: phone,
                    fullName: fullName,
                    email: newEmail,
                    isUserRegistered: true
                };

                // Обновление данных в Firebase и Redux
                await dispatch(updateUserData(user.uid, updatedData));
                const userid = user.uid.substring(0, 10);
                localStorage.setItem('userId', userid);

                navigate(`/${userid}/PersoneHistory`);

                // alert('Email and password updated successfully');
            } catch (error) {
                alert('Error updating email and password:', error.message);
            }

        }
        setLoading(false);
    };

    return (
        <div className="signup-container" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", backgroundColor: '#dcdce2' }}>

                <form className="signup-content signup-card-left" onSubmit={handleUpdateEmailAndPassword}>
                    <div className="signup-card">
                        <h2>{t('please_enter_a_temporary_email_and_password')}</h2>

                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            placeholder={t("temporary_email")}

                            style={{ marginBottom: 10 }}

                        />

                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={t("temporary_password")}

                            style={{ marginBottom: 10 }}

                        />

                        <input
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                            label="full_name"
                            placeholder={t("full-name")}

                            style={{ marginBottom: 10 }}

                        />

                        <input
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            placeholder={t("new_email")}

                            style={{ marginBottom: 10 }}

                        />
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder={t("new_password")}

                            style={{ marginBottom: 10 }}

                        />

                        <input
                            type="password"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            placeholder={t("confirm_your_new_password")}

                            style={{ marginBottom: 10 }}

                        />
                        <PhoneInputField
                            value={phone}
                            onChange={setPhone}
                            userLanguage={userLanguage}
                        />
                        <button
                            className="package-button"
                            type="submit">
                            {t('next')}
                        </button>
                    </div>
                </form>

                {registrationError && (
                    <p style={{ color: 'red', textAlign: 'center' }}>{registrationError}</p>
                )}
            </div>
        </div >
    );
};

export default UpdateEmail;
