import React, { useState, useEffect } from "react";
import { auth, db } from "../../../Components/Firebase/Firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import { t } from "i18next";
import { generateQRCode } from "../../../Components/GenerateQRCode/GenerateQrCodeFunction";

const CreateDefaultUser = ({ userLanguage, setLoading }) => {
    const [createNewUserUId, setСreateNewUserUId] = useState(localStorage.getItem('createNewUserUId') || null);
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userPrice, setUserPrice] = useState("");
    const [city, setCity] = useState('Tel aviv');
    const [country, setCountry] = useState('Israel');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [flatNumber, setFlatNumber] = useState('');
    const [zipCode, setZipCode] = useState("00000000");
    const [discription, setDiscription] = useState('');
    const [phone, setPhone] = useState('+972 00 000 0000');
    const [source, setSource] = useState('');

    const firstNamePersone = null;
    const lastNamePersone = null;
    const fathersNamePersons = null;
    const birthDatePersone = null;
    const deathDatePersone = null;
    const storyPersone = null;
    const placeOfBirth = null;
    const memorialPlace = null;
    const spouse = null;
    const children = null;
    const education = null;
    const hobbies = null;
    const userLocation = null;
    const fullName = null;

    const isUserRegistered = false;
    const createUserWithQrCode = true;
    const heshbonNumber = Math.floor(Math.random() * 100000);
    const orderNumber = heshbonNumber;
    const saveUserPackage = "Special"

    const registerWithEmailAndPassword = async () => {
        setLoading(true);
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);
            if (res.user) {
                const userDocRef = doc(db, "users", res.user.uid);
                const unsubscribe = onSnapshot(userDocRef, () => {
                    unsubscribe();
                });

                await setDoc(userDocRef, {
                    uid: res.user.uid,
                    email,
                    password,
                    fullName,
                    userPrice,
                    city,
                    country,
                    discription,
                    flatNumber,
                    houseNumber,
                    orderNumber,
                    phone,
                    source,
                    street,
                    zipCode,
                    saveUserPackage,
                    firstNamePersone,
                    lastNamePersone,
                    fathersNamePersons,
                    birthDatePersone,
                    deathDatePersone,
                    storyPersone,
                    placeOfBirth,
                    memorialPlace,
                    spouse,
                    children,
                    education,
                    hobbies,
                    userLocation,
                    isUserRegistered,
                    createUserWithQrCode,
                    dateOfCreation: new Date().toISOString(),
                });

                generateQRCode({
                    userId: res.user.uid,
                    navigate,
                    isCreateUser: true
                });

                // Сохранить новый createNewUserUId в localStorage и в состоянии
                localStorage.setItem('createNewUserUId', res.user.uid);
                setСreateNewUserUId(res.user.uid);
            } else {
                alert("Ошибка при регистрации пользователя");
            }
        } catch (err) {
            console.error(err.message);
            alert("Пользователь с таким email уже существует");
        }
        setLoading(false);
    };

    // Очистить localStorage при загрузке компонента
    useEffect(() => {
        localStorage.removeItem('createNewUserUId');
    }, []);

    return (
        <>
            <div dir={userLanguage?.includes("he") ? "rtl" : "ltr"}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", backgroundColor: '#dcdce2' }}>
                <h2 style={{ textAlign: 'center' }}>Create new user</h2>
                <h2 style={{ textAlign: 'center' }}>{createNewUserUId ? `${createNewUserUId}` : 'No user created yet'}</h2>

                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    placeholder={t("email")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("password")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={userPrice}
                    onChange={(e) => setUserPrice(e.target.value)}
                    placeholder={t("price")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder={t("country")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                    placeholder={t("city")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    required
                    placeholder={t("street")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={houseNumber}
                    onChange={(e) => setHouseNumber(e.target.value)}
                    required
                    placeholder={t("house_number")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={flatNumber}
                    onChange={(e) => setFlatNumber(e.target.value)}
                    required
                    placeholder={t("apartment_number")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    placeholder={t("index")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={discription}
                    onChange={(e) => setDiscription(e.target.value)}
                    placeholder={t("comment_for_delivery")}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="+972"
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <input
                    type="text"
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                    placeholder="Salesperson Name"
                    userLanguage={userLanguage}
                    className="styled-input"
                    style={{ marginBottom: 10 }}
                />

                <button
                    onClick={registerWithEmailAndPassword}
                    className={userLanguage?.includes("he") ? "contact-button contact-button-hebrew" : "contact-button"}
                >
                    Create new user
                </button>
            </div>

            <Footer userLanguage={userLanguage} />
        </>
    );
};

export default CreateDefaultUser;
