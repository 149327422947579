import React, { useEffect, useState, useRef } from 'react';
import { app, auth, storage } from '../../Components/Firebase/Firebase';
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

import { useDispatch, useSelector } from 'react-redux';
import { deletePhoto, editFreeText, getPhotoUrls, getUserData, removeFreeText, updateUserData } from '../../Redux/actions/actions';
import './SettingsPersoneHistory.css';

import { useLocation } from 'react-router-dom';
import noImage from '../../Images/no_data/no-image.png';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';

import { MdAddAPhoto, MdOutlineEdit } from "react-icons/md";
import { IoPerson } from "react-icons/io5";
import { RiInformationFill } from "react-icons/ri";
import { MdAccountCircle } from "react-icons/md";
import { TiLocation } from "react-icons/ti";
import { ImFileText } from "react-icons/im";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";
import fallbackImage from '../../Images/home-page-images/photo_book_open.jpg'; // Убедитесь, что путь к изображению правильный


import FreeTextSection from '../FreeTextSection/FreeTextSection';
import Modal from 'react-modal';
import { getAuth, signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth';
import Alert from '../../Components/Alert/Alert';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';


const SettingsPersoneHistory = ({ userLanguage, storedUserId, setLoading }) => {

    // const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const state = location?.state;

    const numberTab = state && state.numberTab;

    const currentUser = useSelector((state) => state.userData);

    //get userId from firebase
    const getCurrentUser = auth?.currentUser?.uid;

    useEffect(() => {
        setLoading(true);
        dispatch(getUserData());
        setLoading(false);
    }, [dispatch, setLoading]);

    const {
        birthDatePersone,
        deathDatePersone,
        firstNamePersone,
        lastNamePersone,
        fathersNamePersons,
        storyPersone,
        memorialPlace,
        spouse,
        children,
        education,
        hobbies,
        placeOfBirth,
        userLocation,
        // permissionLocation,
        freeTexts,
        uid
    } = currentUser;


    // Управляемые состояния для каждого поля
    const [birthDatePersoneUpadte, setBirthDatePersoneUpadte] = useState(birthDatePersone);
    const [deathDatePersoneUpadte, setDeathDatePersoneUpadte] = useState(deathDatePersone);
    const [firstNamePersoneUpadte, setFirstNamePersoneUpadte] = useState(firstNamePersone);
    const [lastNamePersoneUpadte, setLastNamePersoneUpadte] = useState(lastNamePersone);
    const [fathersNamePersonsUpdate, setFathersNamePersonsUpdate] = useState(fathersNamePersons)
    const [storyPersoneUpadte, setStoryPersoneUpadte] = useState(storyPersone);
    const [memorialPlaceUpadte, setMemorialPlaceUpadte] = useState(memorialPlace);
    const [spouseUpadte, setSpouseUpadte] = useState(spouse);
    const [childrenUpadte, setChildrenUpadte] = useState(children);
    const [educationUpadte, setEducationUpadte] = useState(education);
    const [hobbiesUpadte, setHobbiesUpadte] = useState(hobbies);
    const [placeOfBirthUpadte, setPlaceOfBirthUpadte] = useState(placeOfBirth);

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await dispatch(getUserData());
            setLoading(false);
        };
        loadData();
    }, [dispatch, setLoading]);


    useEffect(() => {
        if (currentUser && !dataLoaded) {
            setBirthDatePersoneUpadte(birthDatePersone);
            setDeathDatePersoneUpadte(deathDatePersone);
            setFirstNamePersoneUpadte(firstNamePersone);
            setLastNamePersoneUpadte(lastNamePersone);
            setFathersNamePersonsUpdate(fathersNamePersons);
            setStoryPersoneUpadte(storyPersone);
            setMemorialPlaceUpadte(memorialPlace);
            setSpouseUpadte(spouse);
            setChildrenUpadte(children);
            setEducationUpadte(education);
            setHobbiesUpadte(hobbies);
            setPlaceOfBirthUpadte(placeOfBirth);
            if (birthDatePersoneUpadte !== "" &&
                deathDatePersoneUpadte !== "" &&
                firstNamePersoneUpadte !== "" &&
                lastNamePersoneUpadte) {
                setDataLoaded(true); // Устанавливаем флаг, что данные загружены
            }
        }
    }, [birthDatePersone, birthDatePersoneUpadte, children, currentUser, dataLoaded, deathDatePersone, deathDatePersoneUpadte, education, fathersNamePersons, firstNamePersone, firstNamePersoneUpadte, hobbies, lastNamePersone, lastNamePersoneUpadte, memorialPlace, placeOfBirth, spouse, storyPersone]);

    // const [agreeLocation, setAgreeLocation] = useState(permissionLocation);
    const [agreeLocation, setAgreeLocation] = useState(() => {
        return JSON.parse(localStorage.getItem('agreeLocation')) || false;

    }); const [currentPosition, setCurrentPosition] = useState(userLocation);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [registrationError, setRegistrationError] = useState('');

    const [editedAuthorOfText, setEditedAuthorOfText] = useState('');
    const [editIndex, setEditIndex] = useState(null);

    const [alertVisible, setAlertVisible] = useState(false);
    const [alertTitle, setAlertTitle] = useState("");

    // const storage = getStorage(app);

    const [photoUrls, setPhotoUrls] = useState([]);
    const fileInputRef = useRef(null);

    const handleAddPhotoClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            uploadPersoneFoto(file);
        }
    };

    const uploadPersoneFoto = async (file) => {
        setLoading(true);

        const storageRef = ref(storage, `${uid}/profile/profile_photo.jpg`);
        await uploadBytes(storageRef, file);

        const downloadURL = await getDownloadURL(storageRef);
        setPhotoUrls([downloadURL]);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        const storageRef = ref(storage, `${uid}/profile`);

        listAll(storageRef)
            .then((result) => Promise.all(result.items.map((item) => getDownloadURL(item))))
            .then((urls) => {
                setPhotoUrls(urls);
            })
            .finally(() => {
                setLoading(false);
            });
        setLoading(false);
    }, [email, setLoading, uid]);

    const handleSaveChanges = async () => {

        if (firstNamePersoneUpadte === "" || firstNamePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('the_name_is_required'));

        } else if (lastNamePersoneUpadte === "" || lastNamePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('last_name_is_required'));

        } else if (birthDatePersoneUpadte === "" || birthDatePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('date_of_birth_is_required'));

        } else if (deathDatePersoneUpadte === "" || deathDatePersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('date_of_departure_is_required'));

        } else if (storyPersoneUpadte === "" || storyPersoneUpadte === null) {
            setAlertVisible(true);
            setAlertTitle(t('please_tell_us_about_the_person_this_is_a_required_field'));

        }
        else {
            const updatedData = {
                firstNamePersone: firstNamePersoneUpadte,
                lastNamePersone: lastNamePersoneUpadte,
                // fathersNamePersons: fathersNamePersonsUpdate,
                birthDatePersone: birthDatePersoneUpadte,
                deathDatePersone: deathDatePersoneUpadte,
                storyPersone: storyPersoneUpadte,
                placeOfBirth: placeOfBirthUpadte,
                memorialPlace: memorialPlaceUpadte,
                spouse: spouseUpadte,
                children: childrenUpadte,
                education: educationUpadte,
                hobbies: hobbiesUpadte,
                userLocation: currentPosition,
                permissionLocation: agreeLocation
            };



            // Обновление данных в Firebase и Redux
            await dispatch(updateUserData(getCurrentUser, updatedData));
            alert(t('changes_have_been_saved'))
            // navigate(`/${storedUserId}/PersoneHistory`);
        }
    };

    const error_checking_or_deleting_image = t('error_checking_or_deleting_image')

    const handleDeletePhoto = async (url) => {
        setLoading(true);
        try {
            // Удаляем фотографию
            await dispatch(deletePhoto(uid, url));

            // Получаем обновленный список фотографий
            await dispatch(getPhotoUrls(uid));
        } catch (error) {
            console.error(error_checking_or_deleting_image + ' ', error);
        } finally {
            setLoading(false);
        }
        setLoading(false);

    };

    useEffect(() => {
        if (!agreeLocation) {
            const permissionGranted = window.confirm('Для определения вашего местоположения, пожалуйста, разрешите доступ к геопозиции.');
            if (permissionGranted) {
                setAgreeLocation(true);
                localStorage.setItem('agreeLocation', true);
                setCurrentPosition(null); // Сбросим текущую позицию, чтобы вызвать useEffect и обновить местоположение
            }
        }
    }, [agreeLocation]);

    useEffect(() => {
        if (agreeLocation && currentPosition === null) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCurrentPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        }
    }, [agreeLocation, currentPosition]);

    // useEffect(() => {
    //     if (agreeLocation && (!userLocation || userLocation?.latitude === undefined || userLocation?.longitude === undefined)) {
    //         navigator.geolocation.getCurrentPosition(
    //             position => {
    //                 setCurrentPosition({
    //                     latitude: position?.coords?.latitude,
    //                     longitude: position?.coords?.longitude,
    //                 });
    //             },
    //             error => {
    //                 console.error('Error getting user location:', error);
    //                 setCurrentPosition({ latitude: 32.109333, longitude: 34.855499 });
    //             }
    //         );
    //     }
    // }, [agreeLocation, userLocation]);

    const markerIcon = L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(<FaMapMarkerAlt color='red' size={30} />)
    });

    const [activeTab, setActiveTab] = useState(numberTab ? numberTab : 1);

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    }
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const alert_please_enter_a_valid_email_address = t('alert_please_enter_a_valid_email_address');
    const alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number = t('alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number');

    const updateEmailAndPassword = async () => {
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
        const passwordRegex = /^(?=.*\d)[\s\S]{6,}$/;

        // Проверяем, что все поля заполнены
        if (newEmail !== "" && newPassword !== "" && confirmNewPassword !== "") {
            setLoading(true);

            // Проверяем формат электронной почты
            if (!emailRegex.test(newEmail)) {
                setRegistrationError(alert_please_enter_a_valid_email_address);
                setLoading(false);
                return;
            }

            // Проверяем формат пароля
            if (!passwordRegex.test(newPassword)) {
                setRegistrationError(alert_the_password_must_contain_at_least_6_characters_and_at_least_one_number);
                setLoading(false);
                return;
            }

            const auth = getAuth(app);
            try {
                // Авторизуем пользователя с текущими учетными данными
                await signInWithEmailAndPassword(auth, email, password);

                // Если авторизация прошла успешно, обновляем электронный адрес и пароль
                const user = auth.currentUser;

                // Обновляем электронный адрес
                await updateEmail(user, newEmail);

                // Обновляем пароль
                await updatePassword(user, newPassword);

                // Обновляем данные в коллекции
                const updatedData = {
                    email: newEmail,
                    password: newPassword
                };

                // Обновление данных в Firebase и Redux
                await dispatch(updateUserData(user.uid, updatedData));

                // Очищаем поля ввода
                setNewEmail('');
                setNewPassword('');
                setConfirmNewPassword('');

                setLoading(false);
                // alert('Email and password updated successfully');
            } catch (error) {
                setLoading(false);
                alert('Error updating email and password:', error.message);
            }
        } else {
            // Если не все поля заполнены, выводим сообщение об ошибке
            alert(t('please_fill_in_all_fields'));
        }
    }

    const handleRemoveMemory = (memory) => {
        const confirmDelete = window.confirm(t('are_you_sure_you_want_to_delete_this_memory'));

        if (confirmDelete) {
            // Вызываем функцию для удаления элемента
            dispatch(removeFreeText(uid, memory));
            getUserData();
        }
    };

    const handleEditMemory = (index) => {
        setEditIndex(index);
        const memory = freeTexts[index];
        setEditedAuthorOfText(memory.freeTextData);
    }

    const handleSaveEdit = (index, author) => {
        setLoading(true);
        dispatch(editFreeText(index, author, uid, editedAuthorOfText));
        setEditedAuthorOfText('');
        setEditIndex(null);
        getUserData();
        setLoading(false);

    };

    const removePosition = () => {
        setAgreeLocation(false)
        setCurrentPosition(null)
    }

    const [backgroundPhotoUrl, setBackgroundPhotoUrl] = useState('');
    const fileInputBackgroundRef = useRef(null);


    const handleBackgroundPhotoUpload = async (file) => {
        setLoading(true);
        const storageRef = ref(storage, `${uid}/background_photo/profile_background.jpg`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setBackgroundPhotoUrl(downloadURL);
        setLoading(false);
    };

    const handleAddPhotoClickBackgroundPhoto = () => {
        fileInputBackgroundRef.current?.click();
    };

    const handleFileChangeBackgroundPhoto = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleBackgroundPhotoUpload(file);
        }
    };
    useEffect(() => {
        const fetchBackgroundPhoto = async () => {
            setBackgroundPhotoUrl(fallbackImage); // Установить резервное изображение при ошибке

            try {
                const storageRef = ref(storage, `${uid}/background_photo/profile_background.jpg`);
                const downloadURL = await getDownloadURL(storageRef);
                setBackgroundPhotoUrl(downloadURL);

            } catch (error) {
                console.error('Error fetching background photo:', error);
                setBackgroundPhotoUrl(fallbackImage); // Установить резервное изображение при ошибке
            }
        };

        if (uid) {
            fetchBackgroundPhoto();
        }
    }, [uid]);

    return (
        <div id="mapId" dir={userLanguage?.includes("he") ? "rtl" : "ltr"}>
            <div className="settings-persone-container" >
                <div className="tabs-container">
                    <div>
                        <div className="tabs">
                            <button
                                className={`tab ${activeTab === 1 ? 'active' : ''}`}
                                onClick={() => handleTabClick(1)}
                            >
                                <div className='tab-names'>
                                    <MdAddAPhoto style={{ color: 'black' }} />
                                    <p style={{ color: 'black' }}>{t('photo')}</p>
                                </div>
                            </button>
                            <button
                                className={`tab ${activeTab === 2 ? 'active' : ''}`}
                                onClick={() => handleTabClick(2)}
                            >
                                <div className='tab-names'>

                                    <IoPerson style={{ color: 'black' }} />

                                    <p style={{ color: 'black' }}>{t('biography')}</p>
                                </div>
                            </button>

                            <button
                                className={`tab ${activeTab === 3 ? 'active' : ''}`}
                                onClick={() => handleTabClick(3)}
                            >
                                <div className='tab-names'>
                                    <RiInformationFill style={{ color: 'black' }} />
                                    <p style={{ color: 'black' }}>{t('information')}</p>
                                </div>

                            </button>

                            <button
                                className={`tab ${activeTab === 4 ? 'active' : ''}`}
                                onClick={() => handleTabClick(4)}
                            >
                                <div className='tab-names'>

                                    <TiLocation style={{ color: 'black' }} />

                                    <p style={{ color: 'black' }}>{t('geolocation')}</p>
                                </div>

                            </button>
                        </div>
                        <div className="tabs">
                            <button
                                className={`tab ${activeTab === 5 ? 'active' : ''}`}
                                onClick={() => handleTabClick(5)}
                            >
                                <div className='tab-names'>

                                    <MdAccountCircle style={{ color: 'black' }} />
                                    <p style={{ color: 'black' }}>{t('account_settings')}</p>
                                </div>

                            </button>
                            <button
                                className={`tab ${activeTab === 6 ? 'active' : ''}`}
                                onClick={() => handleTabClick(6)}
                            >
                                <div className='tab-names'>

                                    <ImFileText style={{ color: 'black' }} />

                                    <p style={{ color: 'black', padding: 2 }}>{t('memories')}</p>
                                </div>

                            </button>
                        </div>
                    </div>

                    <div className="tab-content">
                        {activeTab === 1 && (
                            <>
                                <div className="settings-person-img settings-person">
                                    <h2 className="settings-title">{t('add_photo')}</h2>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                    />
                                    <div className="image-container">
                                        {backgroundPhotoUrl === "" ?
                                            <img
                                                src={fallbackImage}
                                                alt="Background"
                                                loading="eager"
                                                className="background-image"
                                            />
                                            :
                                            <img
                                                src={backgroundPhotoUrl}
                                                alt="Background"
                                                loading="eager"
                                                className="background-image"
                                            />
                                        }

                                    </div>
                                    <input
                                        ref={fileInputBackgroundRef}
                                        type="file"
                                        onChange={handleFileChangeBackgroundPhoto}
                                        style={{ display: 'none' }}
                                    />
                                    <button
                                        onClick={handleAddPhotoClickBackgroundPhoto}
                                        className="button-primary"
                                        style={{ marginTop: 20 }}
                                    >
                                        {t('change_background_photo')}
                                    </button>

                                    {photoUrls?.length > 0 ? (
                                        photoUrls.map((url, index) => (
                                            <div key={index} className="photo-wrapper">
                                                <div className="image-preview">
                                                    <img
                                                        src={url}
                                                        alt="profile-img"
                                                        loading="eager"
                                                        className="background-image" />
                                                </div>
                                                <div className="button-group">
                                                    <button
                                                        className="button-secondary"
                                                        onClick={handleAddPhotoClick}
                                                    >
                                                        {t('change_photo')}
                                                    </button>
                                                    <button
                                                        onClick={() => handleDeletePhoto(url)}
                                                        className="button-danger"
                                                    >
                                                        {t('remove_photo')}
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="settings-person-img settings-person add-photo" onClick={handleAddPhotoClick}>
                                            <h2>{t('change_photo')}</h2>
                                            <img src={noImage}
                                                alt="no-image1"
                                                className="background-image"
                                            />
                                            {photoUrls?.length >= 0 && (
                                                <button
                                                    onClick={handleAddPhotoClick}
                                                    className="button-secondary"

                                                >
                                                    {t('add_photo')}
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {activeTab === 2 && (
                            <div >
                                <h2 style={{ textAlign: "center" }}>{t('change_a_persons_biography')}</h2>
                                <textarea
                                    type="textarea"
                                    value={storyPersoneUpadte}
                                    onChange={(e) => setStoryPersoneUpadte(e.target.value)}
                                    required
                                    label="tell_us_about_the_person"
                                    className={userLanguage?.includes("he") ? "textarea-input-hebrew settings-persone-text-area-size" : "textarea-input settings-persone-text-area-size"}

                                />
                            </div>
                        )}
                        {activeTab === 3 && (
                            <div style={{ display: 'flex', flexDirection: 'column' }} >
                                <h2 style={{ textAlign: 'center' }}>{t('change_information_about_a_person')}</h2>
                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>
                                    {t('name')}
                                </label>
                                <input
                                    type="text"
                                    value={firstNamePersoneUpadte}
                                    onChange={(e) => setFirstNamePersoneUpadte(e.target.value)}
                                    required
                                    placeholder={t("name")}
                                    className="styled-input"
                                    style={{ margin: 10, }}
                                />
                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('surname')}
                                </label>
                                <input
                                    type="text"
                                    value={lastNamePersoneUpadte}
                                    onChange={(e) => setLastNamePersoneUpadte(e.target.value)}
                                    required
                                    placeholder={t("surname")}
                                    className="styled-input"
                                    style={{ margin: 10, }}

                                />

                                {userLanguage?.includes("ru") ?
                                    <>
                                        <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                            {t('fathersName')}
                                        </label>
                                        <input
                                            type="text"
                                            value={fathersNamePersons !== null ? fathersNamePersonsUpdate : ''}
                                            onChange={(e) => setFathersNamePersonsUpdate(e.target.value)}
                                            required
                                            placeholder={t("fathersName")}
                                            className="styled-input"
                                            style={{ margin: 10, }}

                                        />
                                    </>
                                    :
                                    null}

                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('date_of_Birth')}
                                </label>

                                <input
                                    type="date"
                                    value={birthDatePersoneUpadte ? birthDatePersoneUpadte : formattedCurrentDate}
                                    onChange={(e) => setBirthDatePersoneUpadte(e.target.value)}
                                    required
                                    placeholder={t("date_of_Birth")}
                                    className="styled-input"
                                    style={{ margin: 10, }}

                                />

                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('date_of_departure')}
                                </label>
                                <input
                                    type="date"
                                    value={deathDatePersoneUpadte ? deathDatePersoneUpadte : formattedCurrentDate}
                                    onChange={(e) => setDeathDatePersoneUpadte(e.target.value)}
                                    required
                                    placeholder={t("date_of_departure")}
                                    className="styled-input"
                                    style={{ margin: 10, }}


                                />
                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                    <h2 style={{ textAlign: 'center' }}>{t('change_additional_data')}</h2>
                                    <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                        {t('place_of_Birth')}
                                    </label>
                                    <input
                                        type="text"
                                        value={placeOfBirthUpadte}
                                        onChange={(e) => setPlaceOfBirthUpadte(e.target.value)}
                                        placeholder={t("place_of_Birth")}
                                        className="styled-input"
                                        style={{ margin: 10, }}

                                    />
                                    <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                        {t('memorial_site')}
                                    </label>
                                    <input
                                        type="text"
                                        value={memorialPlaceUpadte}
                                        onChange={(e) => setMemorialPlaceUpadte(e.target.value)}
                                        placeholder={t("memorial_site")}
                                        className="styled-input"
                                        style={{ margin: 10, }}

                                    />
                                    <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                        {t('spouse')}
                                    </label>
                                    <input
                                        type="text"
                                        value={spouseUpadte}
                                        onChange={(e) => setSpouseUpadte(e.target.value)}
                                        placeholder={t("spouse")}
                                        className="styled-input"
                                        style={{ margin: 10, }}

                                    />
                                    <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                        {t('children')}
                                    </label>
                                    <input
                                        type="text"
                                        value={childrenUpadte}
                                        onChange={(e) => setChildrenUpadte(e.target.value)}
                                        placeholder={t("children")}
                                        className="styled-input"
                                        style={{ margin: 10, }}

                                    />
                                    <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                        {t('education')}
                                    </label>
                                    <input
                                        type="text"
                                        value={educationUpadte}
                                        onChange={(e) => setEducationUpadte(e.target.value)}
                                        placeholder={t("education")}
                                        className="styled-input"
                                        style={{ margin: 10, }}

                                    />
                                    <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                        {t('hobby')}
                                    </label>
                                    <input
                                        type="text"
                                        value={hobbiesUpadte}
                                        onChange={(e) => setHobbiesUpadte(e.target.value)}
                                        placeholder={t("hobby")}
                                        className="styled-input"
                                        style={{ margin: 10, }}

                                    />

                                </div>

                            </div>
                        )}
                        {activeTab === 4 && (
                            <>
                                {agreeLocation ? (
                                    <MapContainer
                                        center={currentPosition && [currentPosition.latitude, currentPosition.longitude]}
                                        zoom={5}
                                        className='settings-persone-map'
                                    >
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker position={currentPosition && currentPosition.latitude && currentPosition.longitude ? [currentPosition.latitude, currentPosition.longitude] : [32.109333, 34.855499]} icon={markerIcon}>
                                            <Popup>
                                                A pretty CSS3 popup. <br /> Easily customizable.
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                ) : (
                                    <MapContainer
                                        center={[32.109333, 34.855499]} // Начальные координаты (например, Израиль)
                                        zoom={5}
                                        className='settings-persone-map'
                                    >
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {currentPosition && (
                                            <Marker position={[currentPosition?.latitude, currentPosition?.longitude]}>
                                                <Popup>
                                                    A pretty CSS3 popup. <br /> Easily customizable.
                                                </Popup>
                                            </Marker>
                                        )}
                                    </MapContainer>
                                )
                                }
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                                    <RiDeleteBin6Line size={30} onClick={() => removePosition()} />
                                </div>
                            </>
                        )}
                        {activeTab === 5 && (
                            <div style={{ display: 'flex', flexDirection: 'column' }} >
                                <h2 style={{ textAlign: 'center' }}>{t('change_email_and_password')}</h2>
                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('email')}
                                </label>

                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    placeholder={t("email")}
                                    className="styled-input"
                                    style={{ margin: 10, }}

                                />

                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('current_password')}
                                </label>
                                <input
                                    securePassword={true}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder={t("current_password")}
                                    className="styled-input"
                                    style={{ margin: 10, }}

                                />


                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('new_email')}
                                </label>
                                <input
                                    type="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    placeholder={t("new_email")}
                                    className="styled-input"
                                    style={{ margin: 10, }}

                                />

                                <label className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('new_password')}
                                </label>
                                <input
                                    securePassword={true}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder={t("new_password")}
                                    className="styled-input"
                                    style={{ margin: 10, }}

                                />

                                <label
                                    className={userLanguage?.includes("he") ? "settings-persone-input-label-hebrew" : "settings-persone-input-label"}>

                                    {t('confirm_your_new_password')}
                                </label>
                                <input
                                    securePassword={true}
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    placeholder={t("confirm_your_new_password")}
                                    className="styled-input"
                                    style={{ margin: 10, }}


                                />
                            </div>
                        )}
                        {activeTab === 6 && (
                            <div >
                                <h2 style={{ textAlign: 'center' }}> {t('change_memory')}</h2>

                                {freeTexts?.map((memory, index) => (
                                    <div key={index} className='settings-persone-memory-container'>
                                        <h3>{memory.authorOfText}</h3>

                                        {editIndex === index ? (
                                            <div >
                                                <textarea
                                                    type="textarea"
                                                    value={editedAuthorOfText}
                                                    onChange={(e) => setEditedAuthorOfText(e.target.value)}
                                                    style={{ minHeight: 200, maxWidth: 200 }}
                                                    className={userLanguage?.includes("he") ? "textarea-input-hebrew" : "textarea-input"}
                                                />
                                                <div style={{ textAlign: 'center' }}>
                                                    <button
                                                        className="package-button"
                                                        onClick={() => handleSaveEdit(index, memory.authorOfText)} >
                                                        {t('save')}
                                                    </button>
                                                    <button
                                                        className="package-button"
                                                        style={{ marginLeft: 10 }}
                                                        onClick={() => setEditIndex(null)} >
                                                        {t('cancel')}
                                                    </button>
                                                </div>

                                            </div>
                                        ) : (
                                            <div style={{ width: 200 }}>
                                                <p>{memory.freeTextData}</p>
                                                <RiDeleteBin6Line size={30} onClick={() => handleRemoveMemory(memory)} />
                                                <MdOutlineEdit size={30} onClick={() => handleEditMemory(index)} />
                                            </div>
                                        )}

                                    </div>
                                ))}

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <button
                                        className="package-button"
                                        onClick={() => setModalIsOpen(true)} >
                                        {t('add_your_memory')}
                                    </button>
                                </div>


                                <div>
                                    {modalIsOpen && (
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={() => {
                                                setModalIsOpen(false);
                                                // Сбрасываем индекс при закрытии модального окна
                                            }}
                                            contentLabel="Free Text Modal"
                                            className="custom-modal"
                                        >
                                            <FreeTextSection
                                                storedUserId={storedUserId}
                                                setShowFreeTextForm={setModalIsOpen}
                                                userLanguage={userLanguage}
                                                moreBiography={false}
                                            />
                                        </Modal>
                                    )}

                                </div>
                            </div>
                        )}

                        <div className='settings-persone-btn' >
                            {activeTab === 1 ? (
                                <>
                                </>
                            ) : (
                                activeTab === 5 ? (
                                    <>
                                        {registrationError && (
                                            <p style={{ color: 'red', textAlign: 'center', }}>{registrationError}</p>
                                        )}
                                        <button
                                            onClick={updateEmailAndPassword}
                                            className="package-button"
                                        >
                                            {t('save')}
                                        </button>
                                    </>
                                )
                                    : (
                                        activeTab === 6 ? (
                                            null // Здесь можно добавить другую логику, если нужно
                                        ) : (
                                            <button
                                                onClick={handleSaveChanges}
                                                className="package-button"

                                            >
                                                {t('save')}
                                            </button>
                                        )
                                    )
                            )}
                        </div>
                    </div>
                </div>

                {modalIsOpen && (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={() => {
                            setModalIsOpen(false);
                            // Сбрасываем индекс при закрытии модального окна
                        }}
                        contentLabel="Free Text Modal"
                        className="custom-modal"
                    >
                        <h2>{t('add_your_memory')}</h2>
                        <FreeTextSection
                            storedUserId={storedUserId}
                            setShowFreeTextForm={setModalIsOpen}
                            userLanguage={userLanguage}
                            moreBiography={false}
                        />
                    </Modal>
                )}
                {alertVisible && (
                    <Alert
                        title={alertTitle}
                        text={null}
                        icon={"info"}
                        confirmButtonText={"Ok"}
                        onConfirm={() => setAlertVisible(false)}
                        showCancelButton={false}
                        setAlertVisible={setAlertVisible}
                        alertVisible={alertVisible}
                    />
                )}
            </div >

            <Footer userLanguage={userLanguage} />
        </div >
    );
};

export default SettingsPersoneHistory;
